<template>
  <!-- LOGIN PANEL -->
  <div class="container wizard login">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Panel de Ingreso</h2>
      </div>
    </div>

    <form>
      <div class="row content">
        <div class="col-12">
          <b-form-input
            type="text"
            placeholder="Correo Electrónico"
            v-model="form.username"
            required
            class="form-control"
            maxlength="50"
          />
          <b-form-input
            type="password"
            placeholder="Contraseña"
            v-model="form.password"
            required
            class="form-control"
            maxlength="15"
          />
          <!--<div id="emailHelp" class="form-text">Utilice una contraseña segura.</div>-->
        </div>

        <div class="col-12 d-grid gap-2">
          <a class="btn btn-secondary float-right" @click="handleLogin">Ingresar</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ConfigurationService from "../services/configuration.service";

export default {
  data() {
    return {
      configurations: [],
      form: {
        username: "admin",
        password: "cl4v32022",
      },
    };
  },
  mounted: function () {
    ConfigurationService.getAll().then((data) => {
      this.configurations = data;
      //document.documentElement.style.setProperty("--primary", data[0].primaryColor);
    });
  },
  components: {},
  methods: {
    handleLogin() {
      const { username, password } = this.form;
      this.$store.dispatch("auth/login", { username, password }).then(
        () => {
          this.$router.push("/paso-1.html");
        },
        (error) => {
          this.loading = false;
          this.$bvToast.toast("Reingrese las credenciales e intentelo nuevamente por favor", {
            title: `Usuario o contraseña incorrecta`,
            variant: "danger",
            solid: true,
          });
          this.message =
            (error.response && error.response.data) || error.message || error.toString();
        }
      );
    },
  },
};
</script>
