<template>
  <!-- WIZARD STEP # 4 -->
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Chequeo de Datos</h2>
      </div>
    </div>
    <div class="row paginator">
      <div class="col-md-2 col-4">
        <router-link to="/paso-1.html" class="passed">1<i class="paso-1"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-2.html" class="passed">2<i class="paso-2"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-3.html" class="passed">3<i class="paso-3"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-4.html" class="selected">4<i class="paso-4"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-5.html" class="">5<i class="paso-5"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-6.html" class="">6<i class="paso-6"></i></router-link>
      </div>
    </div>

    <div class="row content">
      <div class="col-6">
        <div class="card">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">Nombre: {{ form.nombre }}</li>
            <li class="list-group-item">Email: {{ form.email }}</li>
            <li class="list-group-item">Tipo de identificación: {{ form.tipoDocumento }}</li>
            <li class="list-group-item">Fecha Nacimiento: {{ form.fechaNacimiento }}</li>
            <li class="list-group-item">Estado Civil: {{ form.estadoCivil }}</li>
          </ul>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">Apellidos: {{ form.apellido }}</li>
            <li class="list-group-item">Número movil: {{ form.telefono }}</li>
            <li class="list-group-item">Número de identificación : {{ form.numeroDocumento }}</li>
            <li class="list-group-item">Género: {{ form.genero }}</li>
            <li class="list-group-item">Profesión: {{ form.profesion }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row content">
      <div class="col-12 col-md-6">
        <a @click="previous" class="btn btn-secondary">volver</a>
      </div>
      <div class="col-12 col-md-6 btn-container">
        <a @click="first" class="btn btn-secondary mr-3">modificar</a>
        <a @click="next" class="btn btn-secondary">enviar firma</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    first() {
      this.$router.push("/paso-1.html");
    },
    previous() {
      this.$router.push("/paso-3.html");
    },
    next() {
      this.$router.push("/paso-5.html");
    },
  },
  computed: {
    form() {
      return this.$store.state.wizard.form;
    },
  },
};
</script>
