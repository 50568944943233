<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(next)">
        <div class="container wizard">
          <div class="row">
            <div class="col-12 p-0">
              <h2>Perfil</h2>
            </div>
          </div>
          <div class="row paginator">
            <div class="col-md-2 col-4">
              <router-link to="/paso-1.html" class="passed">1<i class="paso-1"></i></router-link>
            </div>
            <div class="col-md-2 col-4">
              <router-link to="paso-2.html" class="">2<i class="paso-2"></i></router-link>
            </div>
            <div class="col-md-2 col-4">
              <router-link to="paso-3.html" class="">3<i class="paso-3"></i></router-link>
            </div>
            <div class="col-md-2 col-4">
              <router-link to="paso-4.html" class="">4<i class="paso-4"></i></router-link>
            </div>
            <div class="col-md-2 col-4">
              <router-link to="paso-5.html" class="">5<i class="paso-5"></i></router-link>
            </div>
            <div class="col-md-2 col-4">
              <router-link to="paso-6.html" class="">6<i class="paso-6"></i></router-link>
            </div>
          </div>

          <div class="row content">
            <!-- PRIMERA COLUMNA -->
            <div class="col-12 col-md-6">
              <!-- NOMBRE -->
              <validation-provider name="nombre" rules="required|max:30" v-slot="validationContext">
                <b-form-group id="example-input-group-1" label-for="nombre">
                  <b-form-input
                    id="nombre"
                    type="text"
                    v-model="form.nombre"
                    :state="getValidationState(validationContext)"
                    class="form-control"
                    aria-describedby="nombre-live-feedback"
                    placeholder="Nombre"
                    tabindex="1"
                  ></b-form-input>
                  <b-form-invalid-feedback id="nombre-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- EMAIL -->
              <validation-provider
                name="email"
                rules="required|max:40|email"
                v-slot="validationContext"
              >
                <b-form-group id="email-input-group" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    :state="getValidationState(validationContext)"
                    class="form-control"
                    aria-describedby="email-live-feedback"
                    placeholder="Correo Electrónico"
                    tabindex="3"
                  />
                  <b-form-invalid-feedback id="email-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- TIPO DE DOCUMENTO -->
              <validation-provider
                name="Tipo Identificación"
                rules="required"
                v-slot="validationContext"
              >
                <b-form-group id="tipoDocumento-input-group" label-for="tipoDocumento">
                  <b-form-select
                    id="tipoDocumento"
                    v-model="form.tipoDocumento"
                    :state="getValidationState(validationContext)"
                    class="form-control"
                    aria-describedby="tipoDocumento-live-feedback"
                    :options="options.tipoDocumento"
                    tabindex="5"
                  ></b-form-select>

                  <b-form-invalid-feedback id="tipoDocumento-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- FECHA NACIMIENTO  -->
              <div class="row justify-content-end">
                <div>Fecha Nac. :</div>
                <div class="col-6">
                  <validation-provider
                    name="fecha de nacimiento"
                    rules="required|menorAHoy"
                    v-slot="validationContext"
                  >
                    <b-form-group id="fechaNacimiento-input-group" label-for="fechaNacimiento">
                      <b-form-input
                        id="fechaNacimiento"
                        type="date"
                        v-model="form.fechaNacimiento"
                        :state="getValidationState(validationContext)"
                        class="form-control"
                        aria-describedby="fechaNacimiento-live-feedback"
                        tabindex="7"
                      />
                      <b-form-invalid-feedback id="fechaNacimiento-live-feedback">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div>Edad :</div>
                <div class="col-2">
                  <b-form-input
                    id="edad"
                    type="number"
                    :disabled="true"
                    v-model="edad"
                    class="form-control"
                  />
                </div>
              </div>
              <!-- ESTADO CIVIL -->
              <validation-provider name="estado civil" rules="required" v-slot="validationContext">
                <b-form-group id="estadoCivil-input-group" label-for="estadoCivil">
                  <b-form-select
                    id="estadoCivil"
                    v-model="form.estadoCivil"
                    :state="getValidationState(validationContext)"
                    class="form-control"
                    aria-describedby="estadoCivil-live-feedback"
                    :options="options.estadoCivil"
                    tabindex="9"
                  ></b-form-select>
                  <b-form-invalid-feedback id="estadoCivil-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <!-- SEGUNDA COLUMNA -->
            <div class="col-12 col-md-6">
              <!-- APELLIDO -->
              <validation-provider
                name="apellido"
                rules="required|max:30"
                v-slot="validationContext"
              >
                <b-form-group id="apellido-input-group" label-for="apellido">
                  <b-form-input
                    id="apellido"
                    type="text"
                    v-model="form.apellido"
                    :state="getValidationState(validationContext)"
                    class="form-control"
                    aria-describedby="nombre-live-feedback"
                    placeholder="Apellido"
                    maxlength="30"
                    tabindex="2"
                  ></b-form-input>
                  <b-form-invalid-feedback id="apellido-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- regex:/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/-->
              <!-- TELEFONO -->
              <validation-provider
                name="télefono"
                rules="required|max:30"
                v-slot="validationContext"
              >
                <b-form-group id="telefono-input-group" label-for="telefono">
                  <b-form-input
                    id="telefono"
                    type="tel"
                    v-model="form.telefono"
                    :state="getValidationState(validationContext)"
                    class="form-control"
                    aria-describedby="telefono-live-feedback"
                    placeholder="Teléfono Móvil"
                    maxlength="14"
                    tabindex="4"
                  ></b-form-input>
                  <b-form-invalid-feedback id="telefono-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- NUMERO DOCUMENTO -->
              <validation-provider
                name="número identificación"
                rules="required|max:10"
                v-slot="validationContext"
              >
                <b-form-group id="numeroDocumento-input-group" label-for="numeroDocumento">
                  <b-form-input
                    id="numeroDocumento"
                    type="text"
                    size="10"
                    v-model="form.numeroDocumento"
                    :state="getValidationState(validationContext)"
                    class="form-control"
                    aria-describedby="numeroDocumento-live-feedback"
                    placeholder="Número Identificación"
                    tabindex="6"
                  ></b-form-input>
                  <b-form-invalid-feedback id="numeroDocumento-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- GENERO -->
              <validation-provider name="genero" rules="required" v-slot="validationContext">
                <b-form-group id="genero-input-group" label-for="genero">
                  <b-form-select
                    id="género"
                    v-model="form.genero"
                    :state="getValidationState(validationContext)"
                    class="form-control"
                    aria-describedby="genero-live-feedback"
                    :options="options.genero"
                    tabindex="8"
                  ></b-form-select>
                  <b-form-invalid-feedback id="genero-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- PROFESION -->
              <validation-provider
                name="profesión"
                rules="required|max:30"
                v-slot="validationContext"
              >
                <b-form-group id="profesion-input-group" label-for="profesion">
                  <b-form-input
                    id="profesion"
                    type="text"
                    v-model="form.profesion"
                    :state="getValidationState(validationContext)"
                    class="form-control"
                    aria-describedby="genero-live-feedback"
                    placeholder="Profesión"
                    tabindex="10"
                  ></b-form-input>
                  <b-form-invalid-feedback id="profesion-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </div>
          <div class="col-12 btn-container">
            <b-button type="submit" variant="secondary">próximo paso</b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { extend } from "vee-validate";

extend("menorAHoy", (value) => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  const todayFormat = yyyy + "-" + mm + "-" + dd;

  if (value <= todayFormat) {
    return true;
  }
  return "La fecha de nacimiento no puede ser mayor a hoy";
});

export default {
  components: {},

  data() {
    return {
      options: {
        tipoDocumento: [
          { value: null, text: "Tipo Identificación", disabled: true },
          { value: "Cédula Nacional", text: "Cédula Nacional" },
          { value: "Cédula Residencia", text: "Cédula Residencia" },
          { value: "Pasaporte", text: "Pasaporte" },
          { value: "Otro", text: "Otro" },
        ],
        estadoCivil: [
          { value: null, text: "Estado Civil", disabled: true },
          { value: "Soltero", text: "Soltero" },
          { value: "Casado", text: "Casado" },
          { value: "Divorciado", text: "Divorciado" },
          { value: "Viudo", text: "Viudo" },
        ],
        genero: [
          { value: null, text: "Género", disabled: true },
          { value: "Masculino", text: "Masculino" },
          { value: "Femenino", text: "Femenino" },
        ],
      },
      form: {
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
        numeroDocumento: "",
        profesion: "",
        fechaNacimiento: "",
        tipoDocumento: null,
        estadoCivil: null,
        genero: null,
      },
    };
  },
  mounted() {
    const { form } = this.$store.state.wizard;
    this.form = form;
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    next() {
      const form = { ...this.form };

      this.$store
        .dispatch("wizard/save", { index: 1, form })
        .then(this.$router.push("/paso-2.html"));
    },
  },
  computed: {
    edad() {
      let hoy = new Date();
      let fechaNacimiento = new Date(this.form.fechaNacimiento);
      let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
      let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
      if (
        diferenciaMeses < 0 ||
        (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
      ) {
        edad--;
      }
      return edad;
    },
  },
};
</script>
