<template>
  <!-- WIZARD STEP # 5 -->
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Documento con Firma Digital</h2>
      </div>
    </div>
    <div class="row paginator">
      <div class="col-md-2 col-4">
        <router-link to="/paso-1.html" class="passed">1<i class="paso-1"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-2.html" class="passed">2<i class="paso-2"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-3.html" class="passed">3<i class="paso-3"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-4.html" class="passed">4<i class="paso-4"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-5.html" class="passed">5<i class="paso-5"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-6.html" class="selected">6<i class="paso-6"></i></router-link>
      </div>
    </div>

    <div class="row content">
      <div class="col-12">
        <div v-if="accepted" class="alert alert-success" role="alert">
          Se ha realizado la firma del contrato de forma exitosa. Se le enviará por correo una copia
          del mismo.
        </div>
        <div v-if="!accepted" class="alert alert-danger" role="alert">
          El contrato no fue firmado. Por favor en caso de que necesite una revisión, contáctese con
          el gestor.
        </div>
      </div>
    </div>
    <div class="row content">
      <div class="col-12 spacer"></div>
      <div class="col-12 col-md-12 btn-container">
        <a @click="first" class="btn btn-secondary">Generar otro documento</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    first() {
      this.$router.push("/paso-1.html");
    },
  },
  computed: {
    accepted() {
      return this.$store.state.wizard.accepted;
    },
  },
};
</script>
