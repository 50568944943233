<template>
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Customización</h2>
      </div>
    </div>

    <div class="row content">
      <div class="col-12">
        <p>Aquí podrá modificar los estilos de vuestra página.</p>
      </div>
      <div class="col-12 col-md-6">
        <small>Formato estándar 180 x 60px</small>
        <b-form-file
          :state="Boolean(form.logo)"
          placeholder="Cargue aqui su logo"
          drop-placeholder="Drop file here..."
          ref="file"
          accept="image/jpeg"
          @change="handleImage"
        ></b-form-file>
      </div>
      <div class="col-12 col-md-6">
        <small>Máximo 40 caracteres</small>
        <b-form-input
          type="text"
          v-model="form.title"
          aria-describedby="nameHelp"
          placeholder="Escriba aquí su Slogan"
          maxlength="40"
          @update="handleTitles"
        />
      </div>
      <div class="col-12 col-md-6">
        <div class="d-flex justify-content-between">
          <div class="col-6">
            <small>Color primario</small>
            <b-form-input
              v-model="form.primaryColor"
              placeholder="Color Primario"
              type="color"
              @update="handlePrimaryColor"
            ></b-form-input>
          </div>
          <div class="col-6">
            <small>Color texto primario</small>
            <b-form-input
              v-model="form.primaryTextColor"
              placeholder="Color Texto Primario"
              type="color"
              @update="handlePrimaryTextColor"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="d-flex justify-content-between">
          <div class="col-6">
            <small>Color secundario</small>
            <b-form-input
              v-model="form.secondaryColor"
              placeholder="Color Secundario"
              type="color"
              @update="handleSecondaryColor"
            ></b-form-input>
          </div>
          <div class="col-6">
            <small>Color texto secundario</small>
            <b-form-input
              v-model="form.secondaryTextColor"
              placeholder="Color Texto Secundario"
              type="color"
              @update="handleSecondaryTextColor"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <small>Título Sección II - Máximo 40 caracteres</small>
        <b-form-input
          type="text"
          v-model="form.titleSecondSection"
          aria-describedby="nameHelp"
          placeholder="Titulo segunda sección"
          maxlength="40"
          @update="handleTitles"
        />
      </div>
      <div class="col-12 col-md-6">
        <small>Título Sección III - Máximo 40 caracteres</small>
        <b-form-input
          type="text"
          v-model="form.titleThirdSection"
          aria-describedby="nameHelp"
          placeholder="Titulo tercera sección"
          maxlength="40"
          @update="handleTitles"
        />
      </div>
      <div class="col-12 col-md-6">
        <small>Cuerpo Sección II</small>
        <b-form-textarea
          id="bodySecondSection"
          v-model="form.bodySecondSection"
          class="form-control"
          aria-describedby="nameHelp"
          @update="handleTitles"
        ></b-form-textarea>
      </div>
      <div class="col-12 col-md-6">
        <small>Cuerpo Sección III</small>
        <b-form-textarea
          id="bodyThirdSection"
          v-model="form.bodyThirdSection"
          class="form-control"
          aria-describedby="nameHelp"
          @update="handleTitles"
        ></b-form-textarea>
      </div>
      <div class="col-12 col-md-6">
        <small>Método de firma</small>
        <b-form-select
          id="tipodocumento"
          v-model="form.signingMethod"
          class="form-control"
          aria-describedby="nameHelp"
          :options="options.signingMethod"
        ></b-form-select>
      </div>
    </div>
    <div class="row content">
      <div class="col-12 col-md-12 btn-container">
        <a @click="handlerCancelar" class="btn btn-secondary mr-3">Cancelar</a>
        <a @click="handlerAceptar" class="btn btn-secondary">Aceptar</a>
      </div>
    </div>
  </div>
</template>

<script>
import configurationService from "../services/configuration.service";

export default {
  components: {},
  mounted: function () {
    configurationService.getAll().then((data) => {
      this.data = data[0];
      const {
        id,
        title,
        logo,
        primaryColor,
        secondaryColor,
        signingMethod,
        primaryTextColor,
        secondaryTextColor,
        titleSecondSection,
        titleThirdSection,
        bodySecondSection,
        bodyThirdSection,
      } = this.data;
      this.form.id = id;
      this.form.title = title;
      this.form.logo = logo;
      this.form.primaryColor = primaryColor;
      this.form.secondaryColor = secondaryColor;
      this.form.primaryTextColor = primaryTextColor;
      this.form.secondaryTextColor = secondaryTextColor;
      this.form.titleSecondSection = titleSecondSection;
      this.form.titleThirdSection = titleThirdSection;
      this.form.signingMethod = signingMethod;
      this.form.bodySecondSection = bodySecondSection;
      this.form.bodyThirdSection = bodyThirdSection;
    });
  },
  data: function () {
    return {
      options: {
        signingMethod: [
          { value: "null", text: "Metodo de firma", disabled: true },
          { value: "WebClick", text: "Click web" },
          { value: "EmailPin", text: "Envio de PIN al email" },
          { value: "MobilePin", text: "Envio de PIN al móvil" },
          { value: "Handwriting", text: "Firma manuscrita" },
        ],
      },
      data: {},
      form: {
        id: 0,
        title: "",
        logo: null,
        primaryColor: "",
        secondaryColor: "",
        primaryTextColor: "",
        secondaryTextColor: "",
        titleSecondSection: "",
        titleThirdSection: "",
        bodySecondSection: "",
        bodyThirdSection: "",
        signingMethod: "",
      },
    };
  },
  methods: {
    handleImage(e) {
      const file = e.target.files[0];
      const render = new FileReader();
      render.onload = (e) => {
        this.form.logo = e.target.result;
        this.$store.dispatch("app/setting", this.form);
      };
      render.readAsDataURL(file);
    },
    handleTitles() {
      this.$store.dispatch("app/setting", this.form);
    },
    handlePrimaryColor() {
      document.documentElement.style.setProperty("--primary", this.form.primaryColor);
      this.$store.dispatch("app/setting", this.form);
    },
    handleSecondaryColor() {
      document.documentElement.style.setProperty("--secondary", this.form.secondaryColor);
      this.$store.dispatch("app/setting", this.form);
    },
    handlePrimaryTextColor() {
      document.documentElement.style.setProperty("--text-primary", this.form.primaryTextColor);
      this.$store.dispatch("app/setting", this.form);
    },
    handleSecondaryTextColor() {
      document.documentElement.style.setProperty("--text-secondary", this.form.secondaryTextColor);
      this.$store.dispatch("app/setting", this.form);
    },
    handlerCancelar() {
      this.$store.dispatch("app/setting", this.data);
      document.documentElement.style.setProperty("--primary", this.data.primaryColor);
      document.documentElement.style.setProperty("--secondary", this.data.secondaryColor);
      document.documentElement.style.setProperty("--text-primary", this.data.primaryTextColor);
      document.documentElement.style.setProperty("--text-secondary", this.data.secondaryTextColor);
      this.$router.push("/paso-1.html");
    },
    handlerAceptar() {
      configurationService.save(this.form).then((response) => {
        if (response.status == "200") {
          this.$router.push("/paso-1.html");
        }
      });
    },
  },
};
</script>
