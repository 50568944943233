<template>
  <div class="container-fluid header-bg">
    <div class="container">
      <header>
        <div class="row">
          <div class="col-12 col-md-6 navbar-brand">
            <router-link to="/paso-1.html"><img :src="logo" /></router-link>
            <h1>{{ title }}</h1>
          </div>
          <div class="col-12 col-md-6 access-panel" v-if="isLoggedIn">
            {{ username }}
            <router-link to="perfil.html" class="perfil" v-if="isAdmin"></router-link>
            <router-link to="customizacion.html" class="customizacion" v-if="isAdmin"></router-link>
            <router-link to="listado.html" class="listado" v-if="isAdmin"></router-link>
            <a class="desconectarse" @click="logOut"></a>
          </div>
        </div>
      </header>
    </div>
  </div>
  <!--
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/home" exact exact-active-class="active">Home</b-nav-item>
        <b-nav-item to="/about" exact exact-active-class="active">About</b-nav-item>
        <b-nav-item to="/process" exact exact-active-class="active">Process</b-nav-item>
        <b-nav-item to="/config" exact exact-active-class="active">Config</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <template #button-content>
            <em>User</em>
          </template>
          <b-dropdown-item href="#">Profile</b-dropdown-item>
          <b-dropdown-item href="#">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-button variant="primary">hola</b-button>
      </b-navbar-nav>
    </b-collapse>

    -->
</template>

<script>
export default {
  data: function () {
    return {};
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/login.html");
      });
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    username() {
      return this.$store.state.auth.user.username;
    },
    isAdmin() {
      return this.username === "admin";
    },
    title() {
      return this.$store.state.app.title;
    },
    logo() {
      return this.$store.state.app.logo;
    },
    index() {
      return this.$store.state.wizard.form.profesion;
    },
  },
};
</script>
