import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Paso01 from "../views/Paso01.vue";
import Paso02 from "../views/Paso02.vue";
import Paso03 from "../views/Paso03.vue";
import Paso04 from "../views/Paso04.vue";
import Paso05 from "../views/Paso05.vue";
import Paso06 from "../views/Paso06.vue";
import Customizacion from "../views/Customizacion.vue";
import Perfil from "../views/Perfil.vue";
import Listado from "../views/Listado.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Paso1",
    component: Paso01,
  },
  {
    path: "/login.html",
    name: "Login",
    component: Login,
  },
  {
    path: "/paso-1.html",
    name: "Paso1",
    component: Paso01,
  },
  {
    path: "/paso-2.html",
    name: "Paso2",
    component: Paso02,
  },
  {
    path: "/paso-3.html",
    name: "Paso3",
    component: Paso03,
  },
  {
    path: "/paso-4.html",
    name: "Paso4",
    component: Paso04,
  },
  {
    path: "/paso-5.html",
    name: "Paso5",
    component: Paso05,
  },
  {
    path: "/paso-6.html",
    name: "Paso6",
    component: Paso06,
  },
  {
    path: "/customizacion.html",
    name: "Customizacion",
    component: Customizacion,
  },
  {
    path: "/perfil.html",
    name: "Peril",
    component: Perfil,
  },
  {
    path: "/listado.html",
    name: "Listado",
    component: Listado,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/config",
    name: "Config",
    component: () => import(/* webpackChunkName: "about" */ "../views/Config.vue"),
  },

  {
    path: "/process",
    name: "Process",
    component: () => import(/* webpackChunkName: "about" */ "../views/Process.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (to.name !== "Login" && !user) next({ name: "Login" });
  else next();
});

export default router;
