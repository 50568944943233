import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin, OverlayPlugin } from "bootstrap-vue";
import ConfigurationService from "./services/configuration.service";
import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import * as rules from "vee-validate/dist/rules";

// Import Bootstrap an BootstrapVue CSS files (order is important)

import "./main.scss";
import "./custom.css";

//import "bootstrap/dist/css/bootstrap.css";
//import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
// OverlayPlugin
Vue.use(OverlayPlugin);

// Vee Validation
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("es", es);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.config.productionTip = false;

ConfigurationService.getAll().then((data) => {
  document.documentElement.style.setProperty("--primary", data[0].primaryColor);
  document.documentElement.style.setProperty("--secondary", data[0].secondaryColor);
  document.documentElement.style.setProperty("--text-primary", data[0].primaryTextColor);
  document.documentElement.style.setProperty("--text-secondary", data[0].secondaryTextColor);
  store.dispatch("app/setting", data[0]);
  initVue();
});

function initVue() {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}
