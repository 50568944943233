<template>
  <!-- WIZARD STEP # 2 -->
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>{{ title }}</h2>
      </div>
    </div>
    <div class="row paginator">
      <div class="col-md-2 col-4">
        <router-link to="/paso-1.html" class="passed">1<i class="paso-1"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-2.html" class="selected">2<i class="paso-2"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-3.html" class="">3<i class="paso-3"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-4.html" class="">4<i class="paso-4"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-5.html" class="">5<i class="paso-5"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-6.html" class="">6<i class="paso-6"></i></router-link>
      </div>
    </div>
    <div class="row content">
      <div v-html="body"></div>
      <div class="col-12 spacer"></div>
      <div class="col-12 col-md-6">
        <a @click="previous" class="btn btn-secondary">volver</a>
      </div>
      <div class="col-12 col-md-6 btn-container">
        <a @click="next" class="btn btn-secondary">próximo paso</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    previous() {
      this.$router.push("/paso-1.html");
    },
    next() {
      this.$router.push("/paso-3.html");
    },
  },
  computed: {
    title() {
      return this.$store.state.app.titleSecondSection;
    },
    body() {
      return this.$store.state.app.bodySecondSection;
    },
  },
};
</script>
