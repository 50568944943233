<template>
  <!-- WIZARD STEP # 5 -->

  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Documento con Firma Digital</h2>
      </div>
    </div>

    <div class="row paginator">
      <div class="col-md-2 col-4">
        <router-link to="/paso-1.html" class="passed">1<i class="paso-1"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-2.html" class="passed">2<i class="paso-2"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-3.html" class="passed">3<i class="paso-3"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-4.html" class="passed">4<i class="paso-4"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-5.html" class="selected">5<i class="paso-5"></i></router-link>
      </div>
      <div class="col-md-2 col-4">
        <router-link to="paso-6.html" class="">6<i class="paso-6"></i></router-link>
      </div>
    </div>

    <div class="row content">
      <iframe
        v-if="this.url !== ''"
        :src="this.url"
        width="100%"
        height="450px"
        v-on:load="this.loadIFrame"
      ></iframe>
    </div>
  </div>
</template>

<script>
import FormService from "../services/form.service";

export default {
  data() {
    return {
      window: null,
      url: "",
      number: 0,
    };
  },
  components: {},
  mounted: function () {
    this.$store.dispatch("app/loading", true);

    FormService.send(this.form).then((response) => {
      console.log("Send response : ", response.data);
      this.getPdfInterval(response.data.eviID);
    });
  },

  methods: {
    getPdfInterval(uniqueId) {
      var $this = this;

      function requestIntervalFunction(uniqueId) {
        try {
          FormService.status({ uniqueId }).then((response) => {
            if (response.data.statusCode == "200") {
              console.log("Searching Sign ...", response.data);
              var url = response.data.bulkSignUrl;
              var signIn = response.data.signIn;

              if (signIn == "True" || signIn == "False") {
                console.log("Eureka Sign : ");
                $this.$store.dispatch("app/loading", false);
                $this.$store.dispatch("wizard/accepted", signIn == "True");
                clearInterval(requestIntervalId);
                $this.next();
              }

              if ($this.url == "") {
                console.log("Eureka URL: ", url);
                $this.url = url;
              }
            } else {
              console.log("Searching bulk ...", response.data);
            }
          });
        } catch {
          $this.$bvToast.toast(`Contactese con soporte de Evicertia`, {
            title: "Error al intentar recuperar el pdf",
            variant: "danger",
            solid: true,
          });
          $this.$store.dispatch("app/loading", false);
        }
      }

      var requestIntervalId = setInterval(requestIntervalFunction, 2000, uniqueId);
      setTimeout(() => {
        this.$store.dispatch("app/loading", false);
        clearInterval(requestIntervalId);
      }, 120000);
    },
    openUrl() {
      console.log("Abriendo...", this.url);

      window.open(this.url, "_top");

      //`status=no,location=no,toolbar=no,width=1200,height=700,top=${top},left=${left}`

      /*
      this.$store.dispatch("app/loading", true);

      function signInternalRequest(uniqueId) {
        FormService.status({ uniqueId }).then((response) => {
          if (response.data.statusCode == "200") {
            $this.url = response.data.bulkSignUrl;
            $this.$store.dispatch("app/loading", false);

            clearInterval(requestIntervalId);

            console.log("Eureka : ", $this.url);

            $this.$bvToast.toast("Procesa a firmar el documento", {
              title: "Proceso finalizado",
              variant: "success",
              solid: true,
            });

            $this.openUrl();
          } else {
            console.log("Searching...", response.data);
            $this.$store.dispatch("app/loading", false);
            $this.$bvToast.toast(`Contactese con soporte de Evicertia`, {
              title: "Error al intentar recuperar el pdf",
              variant: "danger",
              solid: true,
            });
          }
        });
      }

      var signInternalRequest = setInterval(signInternalRequest, 2000, uniqueId);
      setTimeout(() => {
        this.$store.dispatch("app/loading", false);
        this.$bvToast.toast("Error al intentar recuperar el pdf", {
          title:
            `No se ha podido obtener el PDF, por favor, contacte con Soporte. Su identificador es` +
            uniqueId,
          variant: "danger",
          solid: true,
        });
        clearInterval(signInternalRequest);
      }, 60000);

      */
    },
    loadIFrame(e) {
      if (e.target.src != "") {
        this.$store.dispatch("app/loading", false);
      }
    },
    next() {
      this.$router.push("/paso-6.html");
    },
  },
  computed: {
    form() {
      return this.$store.state.wizard.form;
    },
  },
};
</script>
