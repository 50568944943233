<template>
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Perfil</h2>
      </div>
    </div>

    <div class="row content">
      <div class="col-12">
        <p>Detalle de cliente.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
