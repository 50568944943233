<template>
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Listado de evidencias</h2>
      </div>
    </div>

    <div class="col-12">
      <b-form-group
        label="Filtro"
        label-for="filter-input"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        content-cols=""
        class="mb-0"
      >
        <b-input-group>
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Tipee lo que desea buscar"
            @keydown="currentPage = 1"
          ></b-form-input>

          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="
                filter = '';
                currentPage = 1;
              "
              >Reset</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>

    <div class="row content">
      <div class="col-12">
        <b-table
          striped
          hover
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
        ></b-table>
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import processService from "../services/process.service";

export default {
  data() {
    return {
      filter: "",
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "date",
          label: "Fecha",
          sortable: false,
          formatter: (value) => {
            return value ? value.substring(0, 16).replace("T", " ") : "";
          },
        },
        {
          key: "email",
          label: "Email",
          sortable: false,
        },
        {
          key: "uniqueId",
          label: "EvidenceId",
          sortable: false,
        },
        {
          key: "state",
          label: "Estado",
          sortable: false,
        },
      ],
      items: [],
    };
  },
  mounted: function () {
    processService.getAll({ perPage: 10, currentPage: 1 }).then((data) => {
      this.items = data;
      this.totalRows = data.length;
    });
  },
  onFiltered(items) {
    this.totalRows = items.length;
    this.currentPage = 1;
  },
  /*
  computed: {
    rows() {
      return this.items.length;
    },
  },*/
};
</script>

<style scoped></style>
