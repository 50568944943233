import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = window.location.origin + "/api/";

class ConfigurationService {
  getAll() {
    return axios.get(BASE_URL + "configuration").then((response) => {
      return response.data;
    });
  }
  //
  save(data) {
    return axios
      .post(BASE_URL + "configuration", data, { headers: authHeader() })
      .then((response) => {
        return response;
      });
  }
}

export default new ConfigurationService();
